import React, { useEffect } from 'react';
import { useDomainTestScoreQuery } from './QuizSlice';
import ShowDomainScore from './ShowDomainScore';
import Loading from 'components/global/layout/Loading';
import { useGetSummaryQuery } from 'components/dashboard/panel/panelSlics';
import { useNavigate } from 'react-router-dom';

const DomainScoreCard = () => {
  const employersAssessmentId = localStorage.getItem(
    'localEmployersAssessmentId'
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (!employersAssessmentId) {
      navigate('/');
    }
  }, [employersAssessmentId, navigate]);
  const { isLoading, isSuccess, data } = useDomainTestScoreQuery({
    employersAssessmentId,
  });
  const { refetch } = useGetSummaryQuery({ employersAssessmentId });

  const condition = Boolean(data);
  //Fetch summary so that user sees updated score after navigating to the home screen
  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && !condition && <div>No Score Found</div>}
      {isSuccess && condition && <ShowDomainScore data={data} />}
    </>
  );
};

export default DomainScoreCard;
