import { apiSlice } from '../../../api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSummary: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/user/profile/employers-score-summary',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Summary'],
    }),
  }),
});

export const { useGetSummaryQuery } = extendedApiSlice;
