import React from 'react';
import { stringify } from 'query-string';
import { testSource } from 'config/common';

import {
  useCheckIncompleteQuery,
  useGetQuizQuery,
  useStartTestMutation,
} from '../../QuizSlice';
import classNames from 'classnames';

const StartTestInstructions = (props: any) => {
  const assessmentData = props.assessmentData;
  const employersAssessmentId = localStorage.getItem(
    'localEmployersAssessmentId'
  );

  const currentAssessmentData = assessmentData?.filter((ass: any) => {
    return ass._id === employersAssessmentId;
  })[0];

  const areaId = currentAssessmentData?.domain?._id;
  const skillIds = currentAssessmentData?.skills?.map((skill: any) => {
    return skill._id;
  });

  const paramsObj = {
    areaId,
    skillIds,
    questionCountForEachSkill: 10,
    type: 'B2C DOMAIN',
  };

  // const navigate = useNavigate();
  const { refetch } = useCheckIncompleteQuery({ employersAssessmentId });

  const params = stringify(paramsObj, { arrayFormat: 'index' });

  console.log(paramsObj);

  const { isLoading, isSuccess, isError, data } = useGetQuizQuery(params);

  const startTestObject = {
    domain: areaId,
    skills: skillIds,
    responses: data,
    employersAssessmentId,
    source: testSource.employersAssessment,
  };

  const [startTest, { isLoading: isProcessing, isError: startQuizError }] =
    useStartTestMutation();

  return (
    <div>
      <p>{(isLoading || isProcessing) && 'Loading...'}</p>
      <p>{(isError || startQuizError) && 'Error'}</p>
      <div className='m-1 sm:m-8 bg-gray-50 p-2 sm:p-8'>
        <div className='mb-2 sm:mb-6'>
          The Domain Test evaluates your domain specific concepts and skill sets
        </div>
        <div>Domain : {currentAssessmentData?.domain?.title}</div>
        <div className='flex'>
          Skills :{' '}
          {currentAssessmentData?.skills?.map((skill: any) => {
            return (
              <div key={skill?._id}>
                {' '}
                {skill?.title} {', '}{' '}
              </div>
            );
          })}
        </div>
        <div className='ml-2'>
          <div className='text-primary font-semibold mb-4'>Test Format</div>
          <div className='mb-2 sm:mb-6'>
            Total Questions: 10 MCQs/ skill * no. of skills
          </div>
          <div className='text-primary font-semibold mb-4'>
            General Instructions
          </div>
          <ul className='list-disc'>
            <li>Click on "Get Started" to begin the assessment</li>
            <li>
              You can navigate through questions using Previous/ Next Buttons
            </li>
            <li>
              Remember to finish on time and click on the submit button. If the
              timer runs out while you're still finishing the assessment, your
              answer will be auto-submitted
            </li>
            <li>
              You can preview and edit your answers at the end of the all the
              questions if you are able to finish all your sections before a
              total time of 20 mins
            </li>
            <li>
              After submitting your assessment, you will be able to see your
              scores
            </li>
          </ul>
          <div className='mt-2 sm:mt-6 ml-1'>Note:</div>
          <ul className='list-disc'>
            <li>
              You can not close this tab once the assessment has started,
              otherwise your assessment will be auto-submitted
            </li>
            <li>
              In case of any network issues, please raise a query to the Belong
              Team
            </li>
          </ul>
        </div>
      </div>
      <div
        className={classNames('flex justify-center mt-4 sm:mt-12', {
          hidden: !(isSuccess && data),
        })}
      >
        <button
          disabled={isProcessing}
          onClick={() => {
            startTest(startTestObject)
              .unwrap()
              .then((res) => {
                refetch();
              });
          }}
          className='px-12 py-2 font-semibold text-xl text-primary border border-gray-400 rounded-xl shadow'
        >
          Start Test
        </button>
      </div>
    </div>
  );
};

export default StartTestInstructions;
