import React from 'react';
import AllScore from './AllScore';
import { useGetSummaryQuery } from '../../dashboard/panel/panelSlics';
import { useDomainTestScoreQuery } from '../../belong-score/quiz/QuizSlice';

const Index = () => {
  const employersAssessmentId = localStorage.getItem(
    'localEmployersAssessmentId'
  );
  const { isLoading, data } = useGetSummaryQuery({ employersAssessmentId });
  const { isLoading: domainLoading, data: domainData } =
    useDomainTestScoreQuery({ employersAssessmentId });

  return (
    <>
      {(isLoading || domainLoading) && <div>Loading...</div>}
      {data && domainData && (
        <div className='my-6'>
          {data.aptitude.totalAttempts &&
          data.aptitude.bestAttempt?.finalScore &&
          domainData.length ? (
            <AllScore domainData={domainData} summaryData={data} />
          ) : (
            <div> </div>
          )}
        </div>
      )}
    </>
  );
};

export default Index;
