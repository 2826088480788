import { useGetEmployersAssessmentQuery } from 'components/profile/userSlice';
import React from 'react';
import Loading from 'components/global/layout/Loading';
import StartTestInstructions from './StartTestInstructions';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const GetEmployersAssessment = () => {
  const employersAssessmentId = localStorage.getItem(
    'localEmployersAssessmentId'
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (!employersAssessmentId) {
      navigate('/');
    }
  }, [employersAssessmentId, navigate]);

  const { isSuccess, isLoading, data } = useGetEmployersAssessmentQuery();

  return (
    <div>
      {isLoading && <Loading />}
      {isSuccess && data && data.length > 0 ? (
        <StartTestInstructions assessmentData={data} />
      ) : (
        <div>Assessment Not Found</div>
      )}
    </div>
  );
};

export default GetEmployersAssessment;
