import React from 'react';
import ScoreBar from './ScoreBar';
import classNames from 'classnames';

type infoProps = {
  domainData: any;
  summaryData: any;
  selectedDomain: number;
  setSelectedDomain: Function;
};

const ScoreInfo = (props: infoProps) => {
  const domainData = props.domainData;
  const summaryData = props.summaryData;
  const selectedDomain = props.selectedDomain;
  const belongScore = Math.ceil(
    summaryData.aptitude.bestAttempt.percentage * 0.4 +
      domainData[selectedDomain].percentage * 0.5 +
      summaryData.profileStrength
  );
  return (
    <div>
      <div className='grid grid-cols-5 gap-2'>
        <div className='col-span-5 sm:col-span-1'>
          <div className='text-primary text-center font-semibold text-xl'>
            Belong Score : <span className='text-black'> {belongScore}</span>
          </div>
          <div className='text-center text-sm mt-1 mx-2 md:mx-4 lg:mx-10'>
            Your Employability Proof powered by Belong
          </div>
        </div>
        <div className='col-span-5 sm:col-span-4'>
          <ScoreBar />
        </div>
      </div>
      <div className='flex justify-center'>
        <div className='mt-4'>
          <div className='mt-3 mb-2'>
            Choose the domain to view domain specific Belong Score Analysis :
          </div>
          <div className='flex'>
            {domainData.map((domain: any, index: number) => {
              return (
                <button
                  className={classNames(
                    'm-1 sm:m-2 px-2 py-2 border-2 border-primary-light rounded-lg',
                    {
                      'bg-primary-lightest sm:font-semibold':
                        selectedDomain === index,
                    }
                  )}
                  onClick={() => {
                    props.setSelectedDomain(index);
                  }}
                  key={index}
                >
                  {domain.domain.title}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreInfo;
