import classNames from 'classnames';
import { useMemo, useEffect } from 'react';
import BelongScoreCard from 'components/dashboard/panel/layout/BelongScoreCard';
import { useGetSummaryQuery } from 'components/dashboard/panel/panelSlics';
import {
  checkIfTestInProgress,
  getAptitudeScore,
  getAptitudeUrl,
  getDoaminUrl,
  getDomainScore,
} from 'components/dashboard/panel/utils/helper';
import AptitudeScoreCard from 'components/dashboard/panel/layout/AptitudeScoreCard';
import DomainScoreCard from 'components/dashboard/panel/layout/DomainScoreCard';
import ProfileScoreCard from 'components/dashboard/panel/layout/ProfileScoreCard';
import ReAttempt from './ReAttempt';
import TotalAttempts from './TotalAttempts';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const employersAssessmentId = localStorage.getItem(
    'localEmployersAssessmentId'
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (!employersAssessmentId) {
      navigate('/');
    }
  }, [employersAssessmentId, navigate]);

  const { isLoading, data } = useGetSummaryQuery(
    { employersAssessmentId },
    { skip: !Boolean(employersAssessmentId) }
  );

  const aptitudeData = data?.aptitude;
  const domainData = data?.domain;
  const aptitudeUrlLink = getAptitudeUrl(data);
  const domainUrlLink = getDoaminUrl(data);
  const incompleteTest = checkIfTestInProgress(data);
  const aptitudeScore = useMemo(
    () => getAptitudeScore(aptitudeData?.bestAttempt?.percentage),
    [aptitudeData?.bestAttempt?.percentage]
  );
  const domainScore = useMemo(
    () => getDomainScore(domainData?.bestAttempt?.percentage),
    [domainData?.bestAttempt?.percentage]
  );
  const domainScoreRoundedToOne = Math.round(domainScore.score * 10) / 10;
  const aptitudeScoreRoundedToOne = Math.round(aptitudeScore.score * 10) / 10;

  const belongScore =
    aptitudeScoreRoundedToOne + domainScoreRoundedToOne + data?.profileStrength;

  //To Do: Handle error

  return (
    <div className='grid grid-cols-12 gap-4'>
      <div className='col-span-12 lg:col-span-10'>
        <div className='shadow-lg p-2 sm:p-8'>
          <div
            className={classNames(
              ' gap-4 lg:gap-6 grid grid-cols-2 lg:grid-cols-4',
              {
                'animate-pulse': isLoading,
              }
            )}
          >
            <BelongScoreCard
              score={belongScore}
              url={domainUrlLink}
              incompleteTest={incompleteTest}
            />
            {/* Aptitude */}
            <AptitudeScoreCard
              url={aptitudeUrlLink}
              aptitudeScore={aptitudeScoreRoundedToOne}
              gradiantColor='to-bgIndigo'
              heading='Aptitude Score'
              maxScore={40}
              userScore={0}
              unlocktext='Unlock Opportunities at 25/40'
            />
            {/* Domain */}
            <DomainScoreCard
              url={domainUrlLink}
              domainScore={domainScoreRoundedToOne}
              gradiantColor='to-bgCyan'
              heading='Domain Score'
              maxScore={50}
              userScore={0}
              unlocktext='Unlock Opportunities at 35/50'
            />
            {/* Profile */}
            <ProfileScoreCard
              gradiantColor='to-bgPink'
              heading='Profile Strength'
              maxScore={10}
              userScore={data?.profileStrength || 0}
              unlocktext='Unlock Opportunities at 5/10'
            />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-12 mt-12 space-y-4 md:space-y-0'>
            <TotalAttempts data={data} />
            <ReAttempt data={data} />
          </div>
        </div>
      </div>
      {/* <div className='col-span-3 bg-gray-50 grid place-items-center '></div> */}
    </div>
  );
};

export default Index;
