import React from 'react';
import { Route } from 'react-router-dom';

//import AssessmentHome from 'components/belong-score/quiz';
import AptitudeAssessment from 'components/belong-score/quiz/aptitude';
import DomainAssessment from 'components/belong-score/quiz/domain';
// import SelectDomain from '../components/belong-score/quiz/domain/steps/SelectDomain';
// import SelectSkills from '../components/belong-score/quiz/domain/steps/SelectSkills';
import StartSteps from '../components/belong-score/quiz/domain/StartSteps';
import Steps from 'components/belong-score/quiz/domain/steps';
// import StartTestInstructions from '../components/belong-score/quiz/domain/steps/StartTestInstructions';
import CheckQuizStatus from 'components/belong-score/quiz/aptitude/CheckQuizStatus';
import AptitudeScoreCard from 'components/belong-score/quiz/aptitude/layout/AptitudeScoreCard';
import DomainScoreCard from 'components/belong-score/quiz/DomainScoreCard';
import GetEmployersAssessment from 'components/belong-score/quiz/domain/steps/GetEmployersAssessment';

export default (
  <Route path='belong-score'>
    {/* <Route index element={<AssessmentHome />} /> */}
    <Route path='aptitude'>
      <Route index element={<AptitudeAssessment />} />
      <Route path='aptitude-quiz' element={<CheckQuizStatus />} />
      <Route path='scorecard' element={<AptitudeScoreCard />} />
    </Route>
    <Route path='domain' element={<DomainAssessment />}>
      <Route index element={<StartSteps />} />
      <Route path='steps' element={<Steps />}>
        {/* <Route path='select-domain' element={<SelectDomain />} />
        <Route path='select-skills' element={<SelectSkills />} /> */}
        {/* <Route path='start-test' element={<StartTestInstructions />} /> */}
        <Route path='start-test' element={<GetEmployersAssessment />} />
      </Route>
      <Route path='scorecard'>
        <Route index element={<DomainScoreCard />} />
        <Route path=':areaId' element={<DomainScoreCard />} />
      </Route>
    </Route>
  </Route>
);
