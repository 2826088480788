import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SectionChart = () => {
  const series = [25, 25, 25, 25];
  const options = {
    colors: ['#10ABB7C2', '#10ABB78F', '#10ABB74F', '#10ABB726'],
    labels: [
      'Self Awareness',
      'Observation & Analytics',
      'Business Communication',
      'Reasoning & Creativity',
    ],
    plotOptions: {
      pie: {
        expandOnClick: true,
      },
    },
    chart: {
      width: 400,
      type: 'donut',
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: true,
    },
    legend: {
      show: true,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
          chart: {
            width: 300,
          },
        },
      },
      {
        breakpoint: 1080,
        options: {
          chart: {
            width: 350,
          },
        },
      },
    ],
  };
  return (
    <div>
      <ReactApexChart
        width={400}
        options={options}
        series={series}
        type='donut'
      />
    </div>
  );
};

export default SectionChart;
