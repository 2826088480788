import React, { useState } from 'react';
import ScoreInfo from './ScoreInfo';
import ScoreChart from './ScoreChart';

type allScoreProps = {
  domainData: any;
  summaryData: any;
};

const AllScore = (props: allScoreProps) => {
  const domainData = props.domainData;
  const summaryData = props.summaryData;
  const [selectedDomain, setSelectedDomain] = useState(0);
  return (
    <div>
      <ScoreInfo
        domainData={domainData}
        summaryData={summaryData}
        selectedDomain={selectedDomain}
        setSelectedDomain={setSelectedDomain}
      />
      <ScoreChart summaryData={summaryData} />
    </div>
  );
};

export default AllScore;
