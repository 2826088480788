import React from 'react';
import SectionChart from './SectionChart';

type scoreProps = {
  summaryData: any;
};

const ScoreChart = (props: scoreProps) => {
  const aptitudeData = props.summaryData.aptitude;
  function getCategoryPercentage(cat: any) {
    const currentCategory = aptitudeData.bestAttempt.finalScore.filter(
      (category: any) => {
        return category.category === cat;
      }
    )[0];
    const catPercentage = Math.ceil(
      (currentCategory.obtained * 100) / currentCategory.max
    );
    return catPercentage;
  }

  return (
    <div className='my-10 sm:mx-4 lg:mx-16 xl:mx-24 p-2 sm:p-10 border-primary border-2 rounded'>
      <div className='md:flex'>
        <SectionChart />
        <div className='ml-3 md:ml-6 lg:ml-16 mt-2 mb-4 lg:mb-1'>
          <div>
            The Aptitude test assesses on the following skills :{' '}
            <span className='text-primary'>
              Communication, Analytics, Creativity & Awareness
            </span>
            <ul className='list-disc text-sm mt-4 ml-4'>
              <li>
                Communication -{' '}
                <span className='text-primary'>
                  {getCategoryPercentage('COMMUNICATION')} %
                </span>
              </li>
              <li>
                Analytical Ability-{' '}
                <span className='text-primary'>
                  {getCategoryPercentage('ANALYTICAL_SKILLS')} %
                </span>
              </li>
              <li>
                Creativity-{' '}
                <span className='text-primary'>
                  {getCategoryPercentage('CREATIVITY')} %
                </span>
              </li>
              <li>
                Self Awareness -{' '}
                <span className='text-primary'>
                  {getCategoryPercentage('SELF_AWARENESS')} %
                </span>
              </li>
            </ul>
            {/* <div className='font-semibold text-sm mt-2'>
              {' '}
              Overall Cognitive Acumen -{' '}
              <span className='text-primary'>Good</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreChart;
