import classNames from 'classnames';
import { useMemo } from 'react';
import ApplicationSettings from './layout/ApplicationSettings';
import BelongScoreCard from './layout/BelongScoreCard';
import { useGetSummaryQuery } from './panelSlics';
import {
  checkIfTestInProgress,
  getAptitudeScore,
  getAptitudeUrl,
  getDoaminUrl,
  getDomainScore,
} from './utils/helper';
import AptitudeScoreCard from './layout/AptitudeScoreCard';
import DomainScoreCard from './layout/DomainScoreCard';
import ProfileScoreCard from './layout/ProfileScoreCard';
import { useGetEmployersAssessmentQuery } from '../../profile/userSlice';
import ChooseDomain from './ChooseDomain';

const Index = () => {
  const {
    isSuccess,
    isLoading: employersLoading,
    data: employersAssessmentData,
  } = useGetEmployersAssessmentQuery();

  const employersAssessmentId = localStorage.getItem(
    'localEmployersAssessmentId'
  );

  const currentAssessmentData = employersAssessmentData?.filter((ass: any) => {
    return ass._id === employersAssessmentId;
  });

  const { isLoading, data } = useGetSummaryQuery(
    { employersAssessmentId },
    { skip: !Boolean(employersAssessmentId) }
  );

  const aptitudeData = data?.aptitude;
  const domainData = data?.domain;
  const aptitudeUrlLink = getAptitudeUrl(data);
  const domainUrlLink = getDoaminUrl(data);
  const incompleteTest = checkIfTestInProgress(data);
  const aptitudeScore = useMemo(
    () => getAptitudeScore(aptitudeData?.bestAttempt?.percentage),
    [aptitudeData?.bestAttempt?.percentage]
  );
  const domainScore = useMemo(
    () => getDomainScore(domainData?.bestAttempt?.percentage),
    [domainData?.bestAttempt?.percentage]
  );
  const domainScoreRoundedToOne = Math.round(domainScore.score * 10) / 10;
  const aptitudeScoreRoundedToOne = Math.round(aptitudeScore.score * 10) / 10;

  const belongScore =
    aptitudeScoreRoundedToOne + domainScoreRoundedToOne + data?.profileStrength;

  return (
    <>
      {employersLoading && <div>Loading...</div>}
      {!employersAssessmentId && employersAssessmentData?.length > 1 && (
        <ChooseDomain data={employersAssessmentData} />
      )}
      {isSuccess && employersAssessmentData?.length === 0 && (
        <div>There is No assessment for you</div>
      )}
      {!employersLoading && employersAssessmentId && currentAssessmentData[0] && (
        <div className='grid grid-cols-12 gap-4'>
          <div className='col-span-12'>
            Your assessment for {currentAssessmentData[0]?.company?.name}{' '}
            company
          </div>
          <div className='col-span-12 lg:col-span-10'>
            {/* {Boolean(data?.domain?.totalAttempts) && (
              <div onClick={() => openModal()} className='flex justify-end'>
                <button className='px-2 py-1 rounded flex items-center gap-x-1 border-2 border-primary-light'>
                  {selectedDomainName || userData?.areas[0]?.title}
                  <AiOutlineDown />
                </button>
              </div>
            )} */}
            <div className='shadow-lg p-2 sm:p-8'>
              <div
                className={classNames(
                  ' gap-4 lg:gap-6 grid grid-cols-2 lg:grid-cols-4',
                  {
                    'animate-pulse pointer-events-none': isLoading,
                  }
                )}
              >
                <BelongScoreCard
                  score={belongScore}
                  url={domainUrlLink}
                  incompleteTest={incompleteTest}
                />
                {/* Aptitude */}
                <AptitudeScoreCard
                  url={aptitudeUrlLink}
                  aptitudeScore={aptitudeScoreRoundedToOne}
                  gradiantColor='to-bgIndigo'
                  heading='Aptitude Score'
                  maxScore={40}
                  userScore={0}
                  unlocktext='Unlock Opportunities at 25/40'
                />
                {/* Domain */}
                <DomainScoreCard
                  url={domainUrlLink}
                  domainScore={domainScoreRoundedToOne}
                  gradiantColor='to-bgCyan'
                  heading='Domain Score'
                  maxScore={50}
                  userScore={0}
                  unlocktext='Unlock Opportunities at 35/50'
                />
                {/* Profile */}
                <ProfileScoreCard
                  gradiantColor='to-bgPink'
                  heading='Profile Strength'
                  maxScore={10}
                  userScore={data?.profileStrength || 0}
                  unlocktext='Unlock Opportunities at 5/10'
                />
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-12 mt-12 space-y-4 md:space-y-0'>
                <ApplicationSettings />
              </div>
            </div>
          </div>
          {/* <div className='col-span-3 bg-gray-50 grid place-items-center '></div> */}
        </div>
      )}
    </>
  );
};

export default Index;
