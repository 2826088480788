import React from 'react';

const ChooseDomain = (props: any) => {
  const data = props.data;
  const updateId = (id: any) => {
    localStorage.setItem('localEmployersAssessmentId', id);
    window.location.reload();
  };

  return (
    <div className='grid sm:grid-cols-2'>
      {data.map((ass: any) => {
        return (
          <div
            onClick={() => updateId(ass._id)}
            className='m-4 p-4 border border-2 border-primary-light cursor-pointer'
            key={ass._id}
          >
            <p>{ass.title}</p>
            <p>Company : {ass.company?.name}</p>
            <p>Domain : {ass.domain?.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default ChooseDomain;
