import React from 'react';
import { useCheckIncompleteAptitudeQuery } from './AptitudeQuizSlice';
import GetAptitudeQuestion from './GetAptitudeQuestion';
import StartAptitudeTestScreen from './StartAptitudeTestScreen';
import Loading from 'components/global/layout/Loading';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const CheckQuizStatus = () => {
  const employersAssessmentId = localStorage.getItem(
    'localEmployersAssessmentId'
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (!employersAssessmentId) {
      navigate('/');
    }
  }, [employersAssessmentId, navigate]);

  const { isLoading, isSuccess, data } = useCheckIncompleteAptitudeQuery(
    {
      employersAssessmentId,
    },
    { skip: !Boolean(employersAssessmentId) }
  );
  const condition = isSuccess && Boolean(data.responses);
  return (
    <div>
      {isLoading || !Boolean(employersAssessmentId) ? (
        <Loading />
      ) : condition ? (
        <div>
          <StartAptitudeTestScreen data={data} />
        </div>
      ) : (
        <GetAptitudeQuestion />
      )}
    </div>
  );
};

export default CheckQuizStatus;
