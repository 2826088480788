import React from 'react';

const ScoreBar = () => {
  return (
    <div>
      <div className='text-center'>
        The E-Score determines your Job Fit by evaluating you on the required
        market standard for your dream job
      </div>
      <div className='mx-2 sm:mx-20 mt-6'>
        <div className='flex mb-2'>
          <div className='w-1/2 text-center'>Skill Competency</div>
          <div className='w-2/5 text-center'>Cognitive Acumen</div>
          <div className='w-1/5 text-center'>Profile Strength</div>
        </div>
        <div className='flex'>
          <div className='h-8 w-1/2 bg-primary rounded-l-xl text-center text-white'>
            50%
          </div>
          <div className='h-8 w-2/5 bg-primary-light text-center text-white'>
            40%
          </div>
          <div className='h-8 w-1/5 bg-primary-lighter text-center text-primary rounded-r-xl'>
            10%
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreBar;
