import { apiSlice } from '../../api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<any, void>({
      query: () => '/user/signin',
      transformResponse: (res: any) => res.data,
      providesTags: ['User'],
    }),
    getEmployersAssessment: builder.query<any, void>({
      query: () => '/user/employers-assessment',
      // transformResponse: (res: any) => res.data,
      transformResponse: (res: any) => {
        if (res.data?.length === 1) {
          const employersAssessmentId = res.data[0]?._id;
          localStorage.setItem(
            'localEmployersAssessmentId',
            employersAssessmentId
          );
        }
        return res.data;
      },
      // providesTags: ['EmployersAssessment'],
    }),
    updateUser: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `user`,
          method: 'PUT',
          body,
        };
      },

      async onQueryStarted({ ...data }, { dispatch, queryFulfilled }) {
        // `updateQueryData` requires the endpoint name and cache key arguments,
        // so it knows which piece of cache state to update
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            'getUser' as never,
            undefined as never,
            (draft: object) => {
              // The `draft` is Immer-wrapped and can be "mutated" like in createSlice
              Object.assign(draft, data);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(apiSlice.util.invalidateTags(['User']))
           */
        }
      },
      // Invalidates all queries that subscribe to the `User`.
      invalidatesTags: ['Summary', 'User'],
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useGetEmployersAssessmentQuery,
} = extendedApiSlice;
